<!-- template -->
<template>
  <div class="user_registration__page item__column item__ac">
    <v-form
        @submit.prevent="registration"
        ref="form"
        class="sign__page__block"
    >
    <h3 class="sign__page__title mb-6 mt-6">Тіркеу</h3>
    <v-text-field
        v-model="fio"
        label="ФИО"
        required
        outlined
        class="input"
        type="text"
    ></v-text-field>
    <v-text-field
        v-model="name_business"
        label="Бизнес атауы"
        required
        outlined
        class="input"
        type="text"
    ></v-text-field>
    <v-text-field
        v-model="email"
        label="E-mail"
        required
        outlined
        class="input"
        type="email"
    ></v-text-field>
    <v-text-field
        v-model="password"
        label="Пароль"
        required
        outlined
        class="input"
        type="text"
    ></v-text-field>

    <v-text-field
        v-model="price"
        label="Бағасы"
        required
        outlined
        class="input"
        type="text"
    ></v-text-field>


    <v-btn
        type="submit"
        color="#003E74"
        class="mb-4 button"
        style="color:white; width: 90%"
    >
      Сақтау
    </v-btn>
    </v-form>
  </div>
</template>
<script>
export default {
  data() {
    return {
       fio: '',
       name_business: '',
       email: '',
       password: '',
       price: ''
    }
  },
  beforeMount() {
  },
  mounted() {

  },
  methods: {
    registration() {

      const formData = new FormData();
      formData.append('name_business', this.name);
      formData.append('fio', this.value);
      formData.append('email', this.value);
      formData.append('password', this.value);


      this.$axios({
        method: 'post',
        url: `${this.$API_URL}registration`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          'Content-Type': 'multipart/form-data', // Add this line for proper handling of form data
        },
        data: formData,
      })
          .then(response => {
            this.$toast.open({
              message: response.data.message,
              type: "success",
              position: "bottom",
              duration: 4000,
              queue: true,
            });
            this.getProfile()
            this.link_modal = false
          })
          .catch(error => {
            console.log(error);
          });
    }
  }
}
</script>

<style scoped lang="scss">
.user_registration__page {
  .input {
    width: 90%;
  }
}
</style>